import React from 'react';
import md5 from "md5";
import InputTextElem from "./InputTextElem";
import $ from 'jquery';
import InputNumberElem from "./InputNumberElem";

class VehicleList extends React.Component {
    constructor(props) {
        super(props);
        this.updateRemote = this.updateRemote.bind(this);
        this.renderVehicleNumbers = this.renderVehicleNumbers.bind(this);
        this.onListUpdated = this.onListUpdated.bind(this);
        this.renderVehicleNumbers = this.renderVehicleNumbers.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.renderVehicleNumbersRow = this.renderVehicleNumbersRow.bind(this);
        this.renderStockYardNames = this.renderStockYardNames.bind(this);
        this.onVehicleNoUpdated = this.onVehicleNoUpdated.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.addRow = this.addRow.bind(this);
        this.assignVehicleNumbers = this.assignVehicleNumbers.bind(this);
        this.reset = this.reset.bind(this);
        this.onStockYardNameUpdated = this.onStockYardNameUpdated.bind(this);
    }

    updateRemote(vehicleList) {
        this.props.socket.emit("vehicle-list-updated", vehicleList);
    }

    addStockYard() {

    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("Component did updte", this.props);
        if (md5(JSON.stringify(prevProps.vehicleList)) !== md5(JSON.stringify(this.props.vehicleList))) {
            // console.log("Props have been changed");
            // console.log(this.props);
            this.updateRemote(this.props.vehicleList);
            return 0;
        }
    }

    onListUpdated(event) {
        let newVehicleList = JSON.parse(JSON.stringify(this.props.vehicleList));
        let input = $(event.target);
        let index = input.attr('data-index');
        let fieldName = input.attr('data-field-name');
        newVehicleList[index][fieldName] = input.val();
        this.props.onVehicleListUpdated(newVehicleList);
    }

    renderVehicleNumbers(list) {
        let stockYardIds = Object.keys(list);
        let numberOfVehicleRows = (()=>{
            return stockYardIds.length ? Object.keys(list[stockYardIds[0]]["vehicleNumbers"]).length: 0;
        })();
        return Array(numberOfVehicleRows).fill(1).map((x, y) => x + y).map((rowId)=>{
            return (
                <div className={"vehicle-number-row mb-2 "} key={'vr-'+rowId}>
                    <div className={'order'}>{(rowId)+"." }</div> {this.renderVehicleNumbersRow(rowId)}
                </div>
            )
        });

    }

    onStockYardNameUpdated(event) {
        let newVehicleList = JSON.parse(JSON.stringify(this.props.vehicleList));
        let input = $(event.target);
        let fieldName = input.attr('data-field-name');
        let index = input.attr('data-index');
        newVehicleList[index][fieldName] = fieldName ==="repeatCount" ? parseInt (input.val()) : input.val();
        // console.log("New vehicle list", newVehicleList);
        this.props.onVehicleListUpdated(newVehicleList);
    }
    onVehicleNoUpdated(event) {
        let newVehicleList = JSON.parse(JSON.stringify(this.props.vehicleList));
        let input = $(event.target);
        let index = input.attr('data-index');
        let fieldName = input.attr('data-field-name');
        index = index.split("-");
        newVehicleList[index[0]]['vehicleNumbers'][index[1]][fieldName] = input.val();
        // console.log("New vehicle list", newVehicleList);
        this.props.onVehicleListUpdated(newVehicleList);
        // console.log("Value", input.attr('data-index'), input.val());
    }
    renderVehicleNumbersRow(rowId) {
        // let stockYard = this.props.vehicleList[rowId];
        let vehicleList = this.props.vehicleList;
        return Object.keys(this.props.vehicleList).map((stockYardId, index) => {
            let stockYard = vehicleList[stockYardId];
            return (<div className={'vehicle-number-cell'} key={stockYardId+"-"+rowId}> <InputTextElem obj={stockYard['vehicleNumbers'][rowId]} fieldName="vehicleNo"
                                                                                              onInputChange={this.onVehicleNoUpdated} dataIndex={stockYardId+"-"+rowId} disabled={false}/>
                                                                                              </div>);
        });
    }
    addColumn() {
        this.props.socket.emit('add-vehicle-column');
    }
    assignVehicleNumbers() {
        this.props.socket.emit('assign-vehicle-numbers');
    }
    addRow() {
        this.props.socket.emit('add-vehicle-row');
    }
    reset() {
        if(window.confirm("Are you sure you want to reset Stockyard-Vehicle data ?")) {
            this.props.socket.emit('reset-vehicle-list');
        }

    }
    renderStockYardNames(list) {
        return Object.keys(list).map((stockYardId, index) => {
            return (<div className={'vehicle-number-cell'} key={stockYardId}>
                <InputTextElem obj={list[stockYardId]} fieldName="name"
                               onInputChange={this.onStockYardNameUpdated} dataIndex={stockYardId} disabled={false}/>
                <InputNumberElem obj={list[stockYardId]} fieldName="repeatCount"
                               onInputChange={this.onStockYardNameUpdated} dataIndex={stockYardId} disabled={false} className={"repeat-count"}/>
            </div>);
        });
    }

    render() {
        let vehicleList = this.props.vehicleList;
        return (<div>
            <div className={'btn-list mt-2'}> <button className={'btn btn-primary btn-small'} onClick={this.assignVehicleNumbers}>Assign Vehicle Numbers</button><button className={'btn btn-primary btn-small'} onClick={this.addColumn}>Add column</button><button className={'btn btn-primary btn-small'} onClick={this.addRow}>Add row</button><button className={'btn btn-primary btn-small'} onClick={this.reset}>Reset</button></div>
            {/*<div style={{"text-align": "left"}}>{this.props.vehicleAssigningStatus}</div>*/}
            <div className={'vehicle-number-row stockyard-names mb-3 mt-2 p-1'}>
                {this.renderStockYardNames(vehicleList)}
            </div>
            {this.renderVehicleNumbers(vehicleList)}
        </div>)
    }
}

export default VehicleList;
