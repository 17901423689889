import React from 'react';
import md5 from "md5";
import InputTextElem from "./InputTextElem";
import InputDateElem from "./InputDateElem";
import InputTimeElem from "./InputTimeElem";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import moment from "moment"
import $ from 'jquery';
import InputNumberElem from "./InputNumberElem";

class MachineList extends React.Component {
    constructor(props) {
        super(props);
        // this.category = this.props.category;
        // this.updateStateObj = this.updateStateObj.bind(this);
        this.machineListSortBy = "name";
        this.addLaptop = this.addLaptop.bind(this);
        this.updateRemote = this.updateRemote.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onMachineUpdated = this.onMachineUpdated.bind(this);
        this.openHomePage = this.openHomePage.bind(this);
        this.requestEstimatedTime = this.requestEstimatedTime.bind(this);
        this.executeProxyCommand = this.executeProxyCommand.bind(this);
        this.renderMachineList = this.renderMachineList.bind(this);
        this.resetMachines = this.resetMachines.bind(this);
        this.deleteMachine = this.deleteMachine.bind(this);
        this.onInjectBtnClicked = this.onInjectBtnClicked.bind(this);
        this.sortMachineList = this.sortMachineList.bind(this);
    }

    md5(str) {
        return md5(str);
    };

    sortMachineList(machineList) {
        // console.log("Sorting by", this.machineListSortBy);
        let sortBy = this.machineListSortBy;
        switch (sortBy) {
            case "name":
                machineList = machineList.sort((a,b)=>{
                    return a[sortBy].localeCompare(b[sortBy]);
                });
                break;
            case "estimatedTime2":
                machineList = machineList.sort((a,b)=>{
                    return a[sortBy] - b[sortBy];
                });
                break;
        }

        return machineList
    }

    addLaptop(event) {
        let newLaptopList = JSON.parse(JSON.stringify(this.props.machineList));
        let idPrefix = "LAPTOP";
        let getSuitableIntId = (length)=>{
            let intId = length+1;
            if(newLaptopList[idPrefix+intId]) {
                intId =  getSuitableIntId(intId);
            }
            return intId;
        };
        let newMachineId = idPrefix+getSuitableIntId(Object.keys(newLaptopList).length);
        this.props.socket.emit("add-machine", newMachineId);
        // console.log("New machine ID", newMachineId);
       /* let newMachine = JSON.parse(JSON.stringify({
            id:  newMachineId,
            name: newMachineId,
            serverTime: '',
            estimatedTime: 4070889000000,
            estimatedTime2: 4070889000000,
            userId: "",
            password: "",
            districtCode: "",
            radioBtnText: "",
            formCaptchaImg: "",
            formCaptcha: "",
            vehicleOTP: "",
            vehicleNo: "",
            connectedCount: 0
        }));
        newLaptopList[newMachine.id] = newMachine;
        this.props.onMachineListUpdated(newLaptopList);*/
    }

    resetMachines() {
        var confirmations = 5;
        var i = 0;
        while( i< 5) {
            if(!window.confirm("Are you sure you want to reset laptop data? ")) {
                return
            }
            i++;
        }
        this.props.socket.emit("reset-machines");
        this.props.setMachineListOrderBy("name");
    }
    requestEstimatedTime(event) {
        event.preventDefault();
        let input = $(event.target);
        let machineId = input.attr('data-machine-id');
        this.props.socket.emit("request-estimated-time", machineId);
    }
    openHomePage(event) {
        event.preventDefault();
        let input = $(event.target);
        let machineId = input.attr('data-machine-id');
        this.props.socket.emit("open-page", machineId, "home");
    }
    executeProxyCommand(event) {
        event.preventDefault();
        let input = $(event.target);
        let machineId = input.attr('data-machine-id');
        let command = input.attr('data-command');
        this.props.socket.emit(command, machineId);
    }
    updateRemote(machineList) {
        this.props.socket.emit("machine-list-updated", machineList);
    }

    deleteMachine(event) {
        // let newMachineList = JSON.parse(JSON.stringify(this.props.machineList));

        let input = $(event.currentTarget);
        // console.log(input);
        let index = input.attr('data-machine-id');
        if(!window.confirm("Are you sure you want to close all browsers on "+index+" ?")) {
            return ;
        }
        // console.log("Terminating laptop", index);
        this.props.socket.emit("delete-machine", index);
        /*delete newMachineList[index];
        this.props.onMachineListUpdated(newMachineList);*/
    }

    componentDidUpdate(prevProps, prevState) {
        /*if (this.md5(JSON.stringify(prevProps.machineList)) !== this.md5(JSON.stringify(this.props.machineList))) {
            console.log("Props have been changed");
            console.log(this.props);
            this.updateRemote(this.props.machineList);
            return 0;
        }*/
    }

    onMachineUpdated(event) {
        let newMachineList = JSON.parse(JSON.stringify(this.props.machineList));
        let input = $(event.target);
        let index = input.attr('data-index');
        let fieldName = input.attr('data-field-name');
        newMachineList[index][fieldName] = input.val();
        this.props.onMachineListUpdated(newMachineList);
        this.props.socket.emit("machine-updated", newMachineList[index], index, fieldName);
    }

    onInjectBtnClicked(event) {
        // alert("test");
        event.preventDefault();
        let newMachineList = JSON.parse(JSON.stringify(this.props.machineList));
        let input = $(event.target);
        let index = input.attr('data-index');
        console.log(">>> index >>>", index);
        this.props.socket.emit("inject-delivery-addr-script", newMachineList[index]);
    }

    renderMachineList(machines) {
        let sortedMachineList = this.sortMachineList(Object.values(machines));
        // console.log(sortedMachineList);
        return sortedMachineList.map((machine, index) => {
            // let machine = machines[id];
            // console.log("rendering ", machine);

            let formTimer = this.props.formTimers[machine.id];
            let estimatedTime = (()=>{
                // console.log("KKK", machine.estimatedTime)
                return machine.estimatedTime2 ? moment(machine.estimatedTime2).format('HH:mm:ss.SSS') : "--";
            })();
            // console.log(machine);
            return (
                <tr key={index}>
                    <td><InputTextElem obj={machine} fieldName="name"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        <div><a href="#" onClick={this.openHomePage} data-machine-id={machine.id}>Open Login</a></div>
                    </td>
                    <td style={{minWidth: "4rem"}}><InputTextElem obj={machine} fieldName="loginBtnClickInfo"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        {(()=>{
                            let btnText = machine.proxy ? "Stop proxy ("+machine.proxy+")" : "Start proxy";
                            let command = machine.proxy ? "stop-proxy" : "start-proxy";
                            return (<div><a href="#" onClick={this.executeProxyCommand} data-command={command} data-machine-id={machine.id}>{btnText}</a></div>)
                        })()}
                    </td>
                    <td>{estimatedTime} <div><a href="#" onClick={this.requestEstimatedTime} data-machine-id={machine.id}>Update</a></div>
                    </td>
                    <td><InputTextElem obj={machine} fieldName="userId"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                                       <div style={{'marginTop': "4px"}}><InputTimeElem obj={machine} fieldName="loginPageClickTime"
                                                           onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/></div>
                    </td>
                    <td><InputTextElem obj={machine} fieldName="password"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        <div className={"mt-1"}>
                            <InputTextElem obj={machine} fieldName="deliveryAddress"
                                           onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        </div>
                    </td>
                    <td><InputTextElem obj={machine} fieldName="districtCode"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>

                        <div className={"mt-1"}>
                            <a href="#" onClick={this.onInjectBtnClicked} data-index={machine.id}>Inject</a>
                        </div>
                    </td>
                    <td><InputTextElem obj={machine} fieldName="radioBtnText"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        <div className={"mt-1"} style={{"minWidth": "4em"}}>
                            <InputNumberElem obj={machine} fieldName="pgwId"
                                             onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        </div>
                    </td>
                    <td style={{width: "5%"}}> {machine.formCaptchaImg ? (<img src={machine.formCaptchaImg} alt={'Captcha'}/>) : ""}
                    </td>
                    <td><InputTextElem obj={machine} fieldName="formCaptcha"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        {machine.paymentConfirmed ? (<div className="alert-success" style={{"marginTop": "2px", "fontSize": "10px"}}>Payment confirmed</div>) :""}
                    </td>
                    <td><InputTextElem obj={machine} fieldName="vehicleNo"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        {formTimer && formTimer.orderPageTimer ? (<small className="form-text text-danger">{formTimer.orderPageTimer} </small>) : ""}
                    </td>
                    <td><InputTextElem obj={machine} fieldName="vehicleOTP"
                                       onInputChange={this.onMachineUpdated} dataIndex={machine.id} disabled={false}/>
                        {formTimer && formTimer.otpTimer ? (<small className="form-text text-danger">{formTimer.otpTimer} </small>) : ""}

                    </td>
                    <td>{machine.connectedCount}</td>
                    <td>
                        <button className={'btn btn-primary btn-small'} data-machine-id={machine.id} onClick={this.deleteMachine}> Delete</button>
                    </td>
                </tr>
            )
        })
    }

    render() {
        let machineList = this.props.machineList;
        this.machineListSortBy = this.props.machineListSortBy;
        let self = this;
        // console.log("Machine list", machineList);
        return (<div>
            <div className={'text-end mt-1 mb-2'}>
                <button className={'btn btn-primary'} onClick={this.addLaptop}> Add Laptop</button>
                <button className={'btn btn-primary m-1'} onClick={this.resetMachines}> Reset Laptops </button>
                <BootstrapSwitchButton
                    checked={this.machineListSortBy !== "name"}
                    onlabel='Order by estimated time'
                    width={200}
                    offlabel='Order by name'
                    onChange={(checked) => {
                        checked ? self.props.setMachineListOrderBy("estimatedTime2") : self.props.setMachineListOrderBy("name") ;
                        console.log("Checked >>>", checked);
                    }}
                />
                {/*<button className={'btn btn-primary'}> Select fastest browser</button>*/}
            </div>
            <table className={'table table-stripped'}>
                <thead>
                <tr>
                    <th>Laptop</th>
                    <th>Login Click</th>
                    <th>Est. Time</th>
                    <th>User ID</th>
                    <th>Password</th>
                    <th>District Code</th>
                    <th>Radio Text</th>
                    <th>Captcha</th>
                    <th>Captcha Text</th>
                    <th>Vehicle No</th>
                    <th>Vehicle OTP</th>
                    <th>Browsers</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {this.renderMachineList(machineList)}
                </tbody>
            </table>
        </div>)
    }
}

export default MachineList;
