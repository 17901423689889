import React from 'react';
class InputDateElem extends  React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <input className="form-control input-sm" type="datetime-local" step="0.001" title={this.props.obj[this.props.fieldName]} value={this.props.obj[this.props.fieldName]} data-field-name={this.props.fieldName} onChange={this.props.onInputChange} disabled={this.props.disabled} data-index={this.props.dataIndex !== undefined ? this.props.dataIndex : ''} placeholder={this.props.placeholder !== undefined ? this.props.placeholder : ''}/>
        )
    }
}
export default InputDateElem;


