import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import {io} from "socket.io-client";
import MachineList from "./MachineList";
import VehicleList from "./VehicleList";
import ExtensionSettings from "./ExtensionSettings";
import Login from "./Login";

class App extends React.Component {
    constructor(props) {
        console.log("Initializing the app");
        super(props);
        let machineListSortBy = localStorage.getItem("machineListSortBy");
        machineListSortBy = machineListSortBy ? machineListSortBy : "name";
        this.backendHost = process.env.REACT_APP_BACKEND_HOST;
        this.backendPort = process.env.REACT_APP_BACKEND_PORT;
        this.state = {
            machineList: {},
            vehicleList: {},
            extensionSettings: {},
            formTimers: {},
            machineListSortBy: machineListSortBy,
            vehicleAssigningStatus: "",
            isLoggedIn: true,
        };
        this.onMachineListUpdated=this.onMachineListUpdated.bind(this);
        this.onSingleMachineUpdated=this.onSingleMachineUpdated.bind(this);
        this.onMultipleMachinesUpdated=this.onMultipleMachinesUpdated.bind(this);
        this.onVehicleListUpdated =this.onVehicleListUpdated.bind(this);
        this.onFormTimersUpdated =this.onFormTimersUpdated.bind(this);
        this.onVehicleAssignmentStatusUpdated =this.onVehicleAssignmentStatusUpdated.bind(this);
        this.onExtensionSettingsUpdated =this.onExtensionSettingsUpdated.bind(this);
        this.onMachineListReset =this.onMachineListReset.bind(this);
        this.setMachineListOrderBy =this.setMachineListOrderBy.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.checkLoggedInStatus = this.checkLoggedInStatus.bind(this);
        this.initSocket = this.initSocket.bind(this);
        this.fetchConfig().then(function (data){
            this.configs = data;
            console.log("Configs", this.configs);
            if(this.configs){
                this.backendHost = this.configs.backendServer?.host ? this.configs.backendServer.host : this.backendHost;
                this.backendPort = this.configs.backendServer?.port ? this.configs.backendServer.port : this.backendPort;
            }
            this.backendUrl = window.location.protocol+"//"+this.backendHost+":"+this.backendPort
            this.initSocket();
            this.checkLoggedInStatus();
            console.log(this.backendUrl);
        }.bind(this));
        // this.backendUrl = process.env.REACT_APP_BACKEND_URL;


    }
    async fetchConfig ()  {
        try {
            const response = await fetch('./configs.json');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const config = await response.json();
            return config;
        } catch (error) {
            console.error("Failed to load configuration", error);
            return null;
        }
    };
    copyMachineData = () => {
        // console.log("Machine list", this.state.machineList);
/*        let fieldsToCopy = ["userId", "password", "districtCode", "radioBtnText", "vehicleOTP", "pgwId", "deliveryAddress", "loginPageClickTime"];
        fieldsToCopy = this.configs.dataCopy?.fields ? this.configs.dataCopy.fields : fieldsToCopy;
        let machinesByNames = {};
        let mList = JSON.parse(JSON.stringify(this.state.machineList));
        for (let machineId in mList) {
            let machine = mList[machineId];
            machinesByNames[machine.name] = machine;
        }
        let allMachineNames = Object.keys(machinesByNames);
        const getUniqueValues = (array) => {
            return [...new Set(array)];
        };
        let machineGroupNames = allMachineNames.map((machineName) => {
            return machineName.split("-")[0];
        });
        machineGroupNames = getUniqueValues(machineGroupNames);
        console.log("Machine group names", machineGroupNames);

        for (let machineGroupName of machineGroupNames) {

            let firstMachine = machinesByNames[machineGroupName+"-1"];
            if(!firstMachine){
                continue;
            }
            let updatedMachines = [];
            let machineNamesInTheGroup = allMachineNames.filter((machineName) => {
                return machineName.startsWith(machineGroupName+"-");
            });
            console.log("Machines for group", machineGroupName, machineNamesInTheGroup);

            for(let machineName of machineNamesInTheGroup){
                let machine = machinesByNames[machineName];
                for(let field of fieldsToCopy){
                    machine[field] = firstMachine[field];
                }
                updatedMachines.push(machine);
            }
            // console.log("Updated machines", updatedMachines);
            this.socket.emit("multiple-machine-updated", updatedMachines, fieldsToCopy);

        }*/
        this.socket.emit("copy-machine-data");
        // console.log("Machine list after", mList);
        console.log("Machine state after", this.state.machineList);

    }
    isTheShortcutPressed = (event, shortcut) => {
        let key = event.key;
        let ctrlKey = event.ctrlKey;
        let shiftKey = event.shiftKey;
        let altKey = event.altKey;
        let metaKey = event.metaKey;
        let isShortcut = true;
        if(ctrlKey !== shortcut.includes("ctrl")){
            isShortcut = false;
        }
        if(shiftKey !== shortcut.includes("shift")){
            isShortcut = false;
        }
        if(altKey !== shortcut.includes("alt")){
            isShortcut = false;
        }
        if(metaKey !== shortcut.includes("meta")){
            isShortcut = false;
        }
        if(key !== shortcut[shortcut.length-1]){
            isShortcut = false;
        }
        return isShortcut;
    }
    handleKeyPress = (event) => {
        if(this.configs.dataCopy?.enabled && this.isTheShortcutPressed(event, this.configs.dataCopy?.shortcut)) {
            console.log(event.target);
            this.copyMachineData(event.target);
        }
    };
    componentDidMount() {
        console.log("Component mounted");
        window.addEventListener('keydown', this.handleKeyPress);
    }
    componentDidUpdate(prevProps, prevState, newProps, newState) {
        // console.log("Component updated", prevState, this.state)
        /*if (this.md5(JSON.stringify(prevProps.machineList)) !== this.md5(JSON.stringify(this.props.machineList))) {
            console.log("Props have been changed");
            console.log(this.props);
            this.updateRemote(this.props.machineList);
            return 0;
        }*/
    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }
    initSocket() {
        console.log("Socket", this.socket)
        if(this.socket){
            this.socket.disconnect();
        }
        this.socket =  io(this.backendUrl, {
            'path': "/ui/",
            "query": {
                "token": localStorage.getItem("token")
            }
        });
        this.socket.on('connection', () => {
            alert("Connected to the server");
            console.log("Connected to the server");
        });
        this.socket.on('connect_error', (error) => {
            // alert("Connection error: "+error.message);
            console.error(`Connection error: ${error.message}`);
            if(error.message === "login-required"){
                this.setState({
                    isLoggedIn: false
                });
            }
        });
        this.socket.on("vehicle-list-updated", (data) => {
            // console.log("vehicle list updated");
            // console.log(data); // world
            window.vehicleList = data;
            this.onVehicleListUpdated(data);
        });
        this.socket.on("debug-data", (data) => {
            // console.log("vehicle list updated");
            console.log("debug-data"); // world
            console.log(data);
        });
        this.socket.on("machine-list-updated", (data) => {
            // console.log("machine list updated");
            // console.log(this.state)
            // window.machineList = data;
            this.onMachineListUpdated(data);
            // setMachineList(data)
        });
        this.socket.on("extension-settings-updated", (data) => {
            // console.log("Extension settings updated", data);
            window.extensionSettings = data;
            this.onExtensionSettingsUpdated(data);
            // setMachineList(data)
        });
        this.socket.on("estimated-time-updated", (data) => {
            /*try{
                localStorage.setItem("machineListSortBy", "estimatedTime2");
                this.setState({
                    machineListSortBy: "estimatedTime2"
                });
            }catch(e) {}*/
        });
        this.socket.on("single-machine-updated", (data) => {
            // console.log("machine list updated");
            // this.onMachineListUpdated(data);
            // setMachineList(data)
            this.onSingleMachineUpdated(data);
        });
        this.socket.on("multiple-machines-updated", (data) => {
            // console.log("multiple machines updated");
            // this.onMachineListUpdated(data);
            // setMachineList(data)
            this.onMultipleMachinesUpdated(data);
        });
        this.socket.on("vehicle-assignment-status-updated", (data) => {
            // console.log("machine list updated");
            this.onVehicleAssignmentStatusUpdated(data);
            // setMachineList(data)
        });
        this.socket.on("form-timers-updated", (data) => {
            // console.log("machine list updated");
            window.machineList = data;
            this.onFormTimersUpdated(data);
            // setMachineList(data)
        });
        window.socket = this.socket;
    }
    checkLoggedInStatus = () => {
        const token = localStorage.getItem('token');
        // alert("checking logged in status :"+ token);
        if(!token) {
            this.setState({isLoggedIn: false});
        }
        fetch(this.backendUrl+'/verify-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({token: token}),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "success") {
                } else {
                    // alert("Token verification failed")
                    this.setState({isLoggedIn: false});
                    localStorage.removeItem('authToken');
                }
            });
    };
    handleLogin  (username, password) {
        let self = this;
        fetch(this.backendUrl+"/login", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, password }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
                self.setState({isLoggedIn: true});
                localStorage.setItem("token", data.token);
                self.initSocket();
            } else {
                 self.setState({isLoggedIn: false});
                 alert("Invalid username or password!");
            }
          });
    };
    onMachineListUpdated(newObj) {
        // console.log("Updating machine list", this.state)
        try{
            this.setState({
                machineList: newObj
            });
        }catch(e) {
            console.log(e);
        }

    }
    onExtensionSettingsUpdated(newObj) {
        try{
            this.setState({
                extensionSettings: newObj
            });
        }catch(e) {

        }

    }
    onMachineListReset(newObj) {
        try{
            localStorage.setItem("machineListSortBy", "name");
            this.setState({
                machineListSortBy: "name"
            });
        }catch(e) {

        }

    }
    setMachineListOrderBy(field) {
        try{
            localStorage.setItem("machineListSortBy", field);
            this.setState({
                machineListSortBy: field
            });
        }catch(e) {

        }

    }
    onSingleMachineUpdated(machine) {
        // console.log("Single machine updated", machine);
        let newMachineList = JSON.parse(JSON.stringify(this.state.machineList));
        // console.log("current state", this);
        // console.log("List before state update",newMachineList)
        newMachineList[machine.id] = machine;
        this.onMachineListUpdated(newMachineList)
    }
    onMultipleMachinesUpdated(machines) {
        console.log("Multiple machines updated", machines);
        // console.log("current state", this);
        let newMachineList = JSON.parse(JSON.stringify(this.state.machineList));
        // console.log("List before state update",newMachineList)
        for(let machine of machines){
            newMachineList[machine.id] = machine;
        }
        // console.log("New machine list", newMachineList)
        this.onMachineListUpdated(newMachineList)

    }
    onVehicleListUpdated(newObj) {
        try{
            this.setState({
                vehicleList: newObj
            });
            // console.log("new state", this.state);
        }catch(e) {
            console.log(e);
        }

    }
    onFormTimersUpdated(newObj) {
        try{
            this.setState({
                formTimers: newObj
            });
        }catch(e) {
            console.log(e);
        }

    }

    onVehicleAssignmentStatusUpdated(newObj) {
        try{
            this.setState({
                vehicleAssigningStatus: newObj
            });
        }catch(e) {
            console.log(e);
        }

    }
    render() {
        // console.log("Machine list>>>>", this.state.machineList);
        let isLoggedIn = this.state.isLoggedIn;
        return isLoggedIn ? (
            <div className="App">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">P5 Web</a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link" href="/logout" onClick={(e)=>{
                                        e.preventDefault();
                                        localStorage.removeItem('token');
                                        this.setState({isLoggedIn: false});
                                    }}>Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <div className={'container'}>
                    <ExtensionSettings extensionSettings={this.state.extensionSettings} socket={this.socket}  onExtensionSettingsUpdated={this.onExtensionSettingsUpdated}/>
                </div>
                <hr/>
                <div className={'container-fluid'}>
                    <VehicleList vehicleAssigningStatus={this.state.vehicleAssigningStatus} socket={this.socket} vehicleList={this.state.vehicleList} onVehicleListUpdated={this.onVehicleListUpdated}/>
                </div>
                <hr/>
                <div  className="container-fluid">
                    <MachineList formTimers={this.state.formTimers} socket={this.socket} machineListSortBy={this.state.machineListSortBy} machineList={this.state.machineList} onMachineListUpdated={this.onMachineListUpdated} setMachineListOrderBy={this.setMachineListOrderBy}/>
                </div>
            </div>
        ):(<Login onLogin={this.handleLogin}/>);
    }
}

export default App;
