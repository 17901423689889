import React from 'react';
import InputNumberElem from "./InputNumberElem";
import InputTextElem from "./InputTextElem";
import $ from 'jquery';

class ExtensionSettings extends React.Component {
    constructor(props) {
        super(props);
        this.onExtensionListUpdated = this.onExtensionListUpdated.bind(this);
    }
    onExtensionListUpdated(event) {
        let newExtSettings = JSON.parse(JSON.stringify(this.props.extensionSettings));
        let input = $(event.target);
        let fieldName = input.attr('data-field-name');
        newExtSettings[fieldName] = input.val();
        this.props.onExtensionSettingsUpdated(newExtSettings);
        this.props.socket.emit("extension-settings-updated", newExtSettings, fieldName);
    }
    render() {
        let extensionSettings = this.props.extensionSettings;
        let extensionSettingsFields = ["delivery1","delivery2","delivery3","delivery4","delivery5" ];
        extensionSettingsFields.forEach((field)=>{
           if(!extensionSettings[field]) {
               extensionSettings[field] = "";
           }
        });
        return(<div className={"mt-2"}>
            <div className={"extension-settings-row"}>
{/*                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Login button click delay<br/>&nbsp;</label>
                    <InputNumberElem obj={extensionSettings} fieldName="loginBtnClickDelay"
                                   onInputChange={this.onExtensionListUpdated}  disabled={false}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Login button click delay when server busy</label>
                    <InputNumberElem obj={extensionSettings} fieldName="loginBtnClickDelayServerBusy"
                                   onInputChange={this.onExtensionListUpdated}  disabled={false}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Login button click delay when server busy after 30 sec</label>
                    <InputNumberElem obj={extensionSettings} fieldName="loginBtnClickDelayServerBusyAfter30Sec"
                                   onInputChange={this.onExtensionListUpdated}  disabled={false}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Latency 1<br/>&nbsp;</label>
                    <InputNumberElem obj={extensionSettings} fieldName="latency1"
                                   onInputChange={this.onExtensionListUpdated}  disabled={false}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Latency 2<br/>&nbsp;</label>
                    <InputNumberElem obj={extensionSettings} fieldName="latency2"
                                   onInputChange={this.onExtensionListUpdated}  disabled={false}/>
                </div>*/}
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Delivery 1</label>
                    <InputTextElem obj={extensionSettings} fieldName="delivery1"
                                     onInputChange={this.onExtensionListUpdated}  disabled={false} placeholder={"E.g. 41,04,031|Vikarabad, 04|Vikarabad, 031"}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Delivery 2</label>
                    <InputTextElem obj={extensionSettings} fieldName="delivery2"
                                     onInputChange={this.onExtensionListUpdated}  disabled={false} placeholder={"E.g. 41,04,031|Vikarabad, 04|Vikarabad, 031"}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Delivery 3</label>
                    <InputTextElem obj={extensionSettings} fieldName="delivery3"
                                     onInputChange={this.onExtensionListUpdated}  disabled={false} placeholder={"E.g. 41,04,031|Vikarabad, 04|Vikarabad, 031"}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Delivery 4</label>
                    <InputTextElem obj={extensionSettings} fieldName="delivery4"
                                     onInputChange={this.onExtensionListUpdated}  disabled={false} placeholder={"E.g. 41,04,031|Vikarabad, 04|Vikarabad, 031"}/>
                </div>
                <div className={"extension-settings-cell"}>
                    <label htmlFor="" className="form-label">Delivery 5</label>
                    <InputTextElem obj={extensionSettings} fieldName="delivery5"
                                     onInputChange={this.onExtensionListUpdated}  disabled={false} placeholder={"E.g. 41,04,031|Vikarabad, 04|Vikarabad, 031"}/>
                </div>
            </div>
        </div>);
    }
}
export default ExtensionSettings;
