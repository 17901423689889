import React from 'react';
class InputTimeElem extends  React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let value = this.props.obj[this.props.fieldName] ? this.props.obj[this.props.fieldName] : "";
        return(
            <input className="form-control input-sm" type="time" step={"1"} title={this.props.obj[this.props.fieldName]} value={value} data-field-name={this.props.fieldName} onChange={this.props.onInputChange} disabled={this.props.disabled} data-index={this.props.dataIndex !== undefined ? this.props.dataIndex : ''} placeholder={this.props.placeholder !== undefined ? this.props.placeholder : ''}/>
        )
    }
}
export default InputTimeElem;


